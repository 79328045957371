import { displayDateString } from "../../utils/display-date-string"

export const generateMultiShareMessage = (multiShare, isAppOnly = false) => {
  const { multiShareExpiredAt, isMultiShareActive } = multiShare
  if (isMultiShareActive) {
    return `Apply for free until ${displayDateString(multiShareExpiredAt)}.`
  }

  return isAppOnly
    ? `Your 30-day free application offer expired on ${displayDateString(
        multiShareExpiredAt,
      )}.`
    : `Your 30-day free application offer expired on ${displayDateString(
        multiShareExpiredAt,
      )}. Select “Yes” to reactivate!`
}
