import React from "react"
import { compose } from "redux"
import includes from "lodash/includes"
import isEmpty from "lodash/isEmpty"
import get from "lodash/get"
import "moment-timezone"
import moment from "moment"

import { ButtonSet } from "components/buttons/button-set"
import DesktopViewOnly from "components/desktop-view"
import SignatureModal from "components/envelope/modal/signature-modal"
import RentSpreeBaseModal from "@rentspree/component-v2/dist/modal"
import TermsModalBody from "components/templates/terms-modal-body"
import ConfirmationModal from "components/templates/confirmation-modal"
import HandleBeforeUnload from "components/handle-before-unload"
import {
  Container,
  Wrapper,
  Header,
  IconBeforeText,
  FooterContainer,
  DocumentList,
} from "components/envelope"
import { APPLICATION_TYPE } from "containers/rental-submission/constants"
import EDIT_ICON from "images/icon/e-sign/edit-icon-black.svg"
import {
  findSignerByRecipient,
  filterFieldsBySigner,
  filterPages,
} from "helpers/signing"
import {
  LRA_REVIEW_AND_SIGN,
  SIGNATURE_TYPE_PROPERTIES,
} from "tracker/tracker-const"
import useTracker from "tracker/use-tracker"
import { tracker } from "tracker"

import {
  TYPES_COMPONENT,
  SIGNABLE_COMPONENT,
  CONFIRM_MODAL_TEXT,
  HEADER_TITLE,
} from "./constants"
import DropBoxWrapper from "../pdf-pages/drop-box-wrapper"
import SignFlag from "../sign-flag"
import PopperDropdown from "./popper-drop-down"
import { DOCUMENT_LIST_ID, RECIPIENT_ROLES } from "../constants"
import { withReducer, withSaga } from "../connect"
import { withConnect } from "./connect"

export const TopbarAndHeader = () => (
  <Header>
    <div>
      <IconBeforeText src={EDIT_ICON} alt="IconSign" />
      <span className="text-title">{HEADER_TITLE}</span>
    </div>
  </Header>
)

export const EnvelopeSign = ({
  envelope,
  haveEnvelope,
  activeId,
  actions,
  recipientsList,
  fieldValidation,
  signatureData,
  isLoadingUpdateEnvelope,
  isSubmittingRental,
  match,
  // isOwner,
  shouldHandleUnload,
  isUploadingSignature,
  isLoadingSavingFile,
  // history,
  isSigned,
  isAcceptedConsent,
  updateConsentSuccess,
  isLoadingUpdateConsent,
  error,
  isLoadingGenerateEnvelopePDF,
  uploadSignatureSuccess,
  isSignatureEdited,
  isLoadingGeneratePdfFileURL,
  rentalSubmission,
}) => {
  const [showSignError, setShowSignError] = React.useState(false)
  const [showSignModal, setShowSignModal] = React.useState({
    show: false,
    mode: "newSign",
  })
  const [showModal, setShowModal] = React.useState(false)
  const [showSignConfirmModal, setShowSignConfirmModal] = React.useState(false)
  const [showConsentModal, setShowConsentModal] = React.useState(
    !isAcceptedConsent,
  )
  const [selectedCheckBox, setSelectedCheckBox] = React.useState(false)
  const signFlagRef = React.useRef(null)

  const envelopeId = get(match, "params.envelopeId")
  const { recipients } = envelope
  const { token: queryToken } = findSignerByRecipient(recipients)

  const validationErrors = get(fieldValidation, "validationErrors", {})
  const signErrors = get(fieldValidation, "signErrors", {})
  const isAllFieldValidationValid = fieldValidation.isValid

  const shouldDisableButton =
    isLoadingGenerateEnvelopePDF ||
    isLoadingSavingFile ||
    isLoadingGeneratePdfFileURL ||
    !isAllFieldValidationValid

  const dropdownItems = [
    {
      label: "Edit",
      onClick: () => {
        setShowSignModal({ show: !showModal })
      },
    },
  ]

  const simulateClickSignFlag = () => signFlagRef.current?.click()

  const onClickFinish = () => {
    if (!isAllFieldValidationValid) {
      setShowSignError(true)
      simulateClickSignFlag()
    } else {
      setShowSignError(false)
      setShowSignConfirmModal(true)
    }
  }

  const signApplication = () => {
    // track event
    tracker.trackEvent(LRA_REVIEW_AND_SIGN.CLICK_CONFIRM_FINISH_SIGN)

    const { files } = envelope
    const signer = findSignerByRecipient(recipients)
    const newFiles = filterPages(
      filterFieldsBySigner(files, fields =>
        fields.filter(field =>
          // Check the email because we need to support the old signer that only has the email
          [
            get(signer, "email"),
            get(signer, "_id"),
            get(signer, "roleId"),
          ].includes(field.assignee),
        ),
      ),
      pages => pages.filter(page => !isEmpty(page.fields)),
    )
    const filesData = { files: newFiles }
    const isNextToDocumentStep = !!rentalSubmission?.screeningOption?.income

    actions.updateEnvelopeSign({
      ...filesData,
      isNextToDocumentStep,
    })
  }

  React.useEffect(
    () => {
      if (isSigned) {
        actions.redirect(null, true)
      }
      setShowSignModal({
        show:
          (includes(SIGNABLE_COMPONENT, activeId.type, false) &&
            activeId.canEdit &&
            isEmpty(activeId.value) &&
            isEmpty(signatureData)) ||
          showModal,
        mode: isEmpty(activeId.value) ? "newSign" : "editSign",
      })

      if (
        activeId.type === TYPES_COMPONENT.DATE_SIGNS &&
        isEmpty(activeId.value)
      ) {
        actions.dateSignedSave({
          dateSigned: moment()
            .toDate()
            .valueOf(),
          timeZone: moment.tz.guess(true),
        })
        simulateClickSignFlag()
      }
      if (updateConsentSuccess) {
        setShowConsentModal(false)
      }
    },
    [
      activeId.type,
      activeId.value,
      activeId.canEdit,
      signatureData,
      showModal,
      updateConsentSuccess,
    ],
  )

  React.useEffect(
    () => {
      if (!showSignModal.show && uploadSignatureSuccess && !isSignatureEdited)
        simulateClickSignFlag()
    },
    [showSignModal.show, uploadSignatureSuccess],
  )

  useTracker(LRA_REVIEW_AND_SIGN.ENTER_APPLICATION_SIGN, {
    template: APPLICATION_TYPE.CAR_LRA,
    recipient_role: RECIPIENT_ROLES.APPLICANT,
  })

  let activeBox = ""
  if (
    !isEmpty(activeId) &&
    activeId.canEdit &&
    activeId.value &&
    includes(SIGNABLE_COMPONENT, activeId.type, false)
  ) {
    activeBox = showModal || showSignModal.show ? "" : activeId.fieldId
  }

  const handleClickConsent = () => {
    actions.updateEnvelopeConsent({ isAcceptedConsent: true }, envelopeId, {
      token: queryToken,
    })
  }

  const onCheckBoxClick = () => {
    setSelectedCheckBox(!selectedCheckBox)
  }

  const handleCloseModal = () => {
    actions.resetActive()
    setShowModal(false)
  }

  const handleEditSignature = value => {
    actions.editSignSignature({ signatureData: value, activeId })
    setShowModal(false)
    tracker.trackEvent(
      LRA_REVIEW_AND_SIGN.REPLACE_SIGNATURE,
      SIGNATURE_TYPE_PROPERTIES.TYPE,
    )
  }

  const handleSaveSignature = value => {
    actions.saveSignSignature({ signatureData: value, activeId })
    tracker.trackEvent(
      LRA_REVIEW_AND_SIGN.ADOPT_SIGNATURE,
      SIGNATURE_TYPE_PROPERTIES.TYPE,
    )
    simulateClickSignFlag()
  }

  const handleUploadEditSignature = value => {
    actions.uploadEditSignSignature(value)
  }

  const handleUploadSaveSignature = value => {
    actions.uploadSaveSignSignature(value)
  }

  const onClickBackToGuidePage = () => actions.redirect(null, true)

  const handleClickCloseOnConfirmationModal = () => {
    tracker.trackEvent(LRA_REVIEW_AND_SIGN.CLICK_KEEP_EDITING)
    setShowSignConfirmModal(false)
  }

  if (
    includes(
      [TYPES_COMPONENT.INITIAL_SIGNS, TYPES_COMPONENT.SIGNATURE_SIGNS],
      activeId.type,
      false,
    ) &&
    isEmpty(activeId.value) &&
    !isEmpty(signatureData)
  ) {
    actions.saveSignSignature({ signatureData, activeId })
    simulateClickSignFlag()
  }
  return (
    <>
      <PopperDropdown popper={activeBox} dropdownItems={dropdownItems} />
      {shouldHandleUnload && <HandleBeforeUnload />}
      <Container id="envelopePage">
        {haveEnvelope && <DesktopViewOnly />}
        <TopbarAndHeader />
        <SignFlag
          ref={signFlagRef}
          template={APPLICATION_TYPE.CAR_LRA}
          showSignError={showSignError}
          setShowSignError={setShowSignError}
        />
        <Wrapper>
          <DocumentList center id={DOCUMENT_LIST_ID}>
            <DropBoxWrapper
              viewMode
              recipientsList={recipientsList}
              actions={actions}
              validationErrors={validationErrors}
              showSignError={showSignError}
              signErrors={signErrors}
            />
          </DocumentList>
        </Wrapper>
      </Container>
      <FooterContainer>
        <ButtonSet
          id="eSignPageButtonSet"
          margin="0 0"
          continueBtnType="submit"
          continueText="Finish Signing"
          text="Back to guide"
          btnHeight="40px"
          hidePrevButton
          hideIconNextButton
          disabledBackButton={shouldDisableButton}
          disabledNextButton={shouldDisableButton}
          onClickBackToDashboardBtn={onClickBackToGuidePage}
          onSaveAndNext={onClickFinish}
        />
      </FooterContainer>
      <SignatureModal
        envelopeId={envelopeId}
        userToken={queryToken}
        showSignModal={showSignModal}
        signatureData={signatureData}
        closeModal={handleCloseModal}
        saveSignSignature={handleSaveSignature}
        editSignSignature={handleEditSignature}
        uploadSaveSignSignature={handleUploadSaveSignature}
        uploadEditSignSignature={handleUploadEditSignature}
        isUploadingSignature={isUploadingSignature}
      />
      <RentSpreeBaseModal
        rootId="#app"
        isOpen={showConsentModal}
        closeButton={false}>
        <TermsModalBody
          title="E-Sign and E-Delivery Consent"
          terms={[
            {
              text:
                "I agree to conduct transactions electronically. I certify that I have access to an internet connection and an electronic device with a modern browser, equipped with ability to view PDF documents or other electronic attachments, and that I have access to an email account through which I can view, download and retain these documents as necessary. I consent to the use of electronic signatures in connection with this agreement and any other agreements related to this property and to receive all notices and copies of documents, including those that are legally required to be delivered to me in writing, at the email address I have provided as part of my application or to the landlord or agent for the property in question, which I will keep updated. I also understand that I may withdraw this consent at any time by sending an email to optout@rentspree.com with the subject line “Opt-Out.” Doing so will mean that the landlord or agent will send me paper copies of documents and conduct transactions in writing from thereon with respect to this property. I will otherwise print documents you send me if I want paper copies or contact the landlord or agent to obtain paper copies.",
            },
          ]}
          error={error}
          selectedCheckBox={selectedCheckBox}
          onCheckBoxClick={onCheckBoxClick}
          handleClickBtn={handleClickConsent}
          isLoading={
            isLoadingUpdateConsent ||
            isLoadingGenerateEnvelopePDF ||
            isLoadingSavingFile
          }
          onClearError={actions.clearError}
        />
      </RentSpreeBaseModal>
      <ConfirmationModal
        id="signConfirmModal"
        title={CONFIRM_MODAL_TEXT.TITLE}
        content={CONFIRM_MODAL_TEXT.INFO}
        confirmBtnTxt={CONFIRM_MODAL_TEXT.SUBMIT}
        cancelBtnTxt={CONFIRM_MODAL_TEXT.CANCEL}
        btnNextMinWidth="134"
        canBeClosedByClickOverlay={false}
        isOpen={showSignConfirmModal}
        onClose={handleClickCloseOnConfirmationModal}
        onClickConfirm={signApplication}
        isLoading={isLoadingUpdateEnvelope || isSubmittingRental.components}
      />
    </>
  )
}

export default compose(
  withConnect,
  withReducer,
  withSaga,
)(EnvelopeSign)
