import React from "react"
import styled from "styled-components"
import {
  Button,
  BlueGradientButton,
  Hr,
  BackButton,
  Icon,
} from "@rentspree/component-v2"

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${props => (props.margin ? props.margin : "50px 0 30px")};
  ${props => props.padding && `padding: ${props.padding}`};
  @media (max-width: 767px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;
    ${props => props.mMargin && `margin: ${props.mMargin}`};
  }
`
export const BackNextContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  @media (max-width: 767px) {
    align-items: center;
    justify-content: space-between;
  }
`

export const FormFooter = ({
  cancelBtn,
  backBtn,
  continueBtn,
  backBtnTitle,
  ...props
}) => (
  <Container {...props}>
    <BackButton
      padding="0px"
      homeIcon
      minWidth="150"
      justifyContent="flex-start"
      semiBold
      text="Back to guide"
      {...cancelBtn}
    />
    <Hr className="visible-xs" width="100%" margin="20px 0px 17px 0px" />
    <BackNextContainer>
      {backBtn ? (
        <Button
          semiBold
          google
          small
          margin="0 15px 0 0"
          type="button"
          {...backBtn}>
          <Icon name="chevron-left" size="sm" />
          {backBtnTitle || "Prev"}
        </Button>
      ) : (
        <div />
      )}
      {continueBtn ? (
        <BlueGradientButton
          small
          shadow
          hasIcon
          semiBold
          text="Save & Next"
          type="submit"
          minWidth={164}
          {...continueBtn}
        />
      ) : (
        <div />
      )}
    </BackNextContainer>
  </Container>
)
