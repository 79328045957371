import {
  startNewRentalCall,
  startPrePaymentRentalCall,
  evaluateExperimentCall,
} from "./constants"

export function startNewRental(screeningRequestId) {
  return startNewRentalCall({ screeningRequestId })
}

// TODO: INIT-649 clean up
export function startPrePaymentRental(screeningRequestId) {
  return startPrePaymentRentalCall({ screeningRequestId })
}

export function evaluateExperiment() {
  return evaluateExperimentCall()
}
