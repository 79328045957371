import { buildPath } from "@rentspree/path"
import { all, takeEvery, put, call, takeLeading } from "redux-saga/effects"

import { redirect } from "containers/wrapper/actions"
import { ROUTE } from "containers/router/constants"
import { addToast } from "containers/toast/actions"
import { TOAST_TYPE } from "containers/toast/constants"

import {
  UPDATE_INCOME_VERIFICATION_STATUS_API,
  CREATE_FINICITY_CONNECT_LINK_API,
  UPDATE_INCOME_VERIFICATION_STATUS_CALL,
  CREATE_FINICITY_CONNECT_LINK_CALL,
  GENERATE_INCOME_VERIFICATION_REPORT_API,
  GET_INCOME_VERIFICATION_CALL,
  GET_INCOME_VERIFICATION_API,
  HANDLE_FINICITY_POPUP_DONE,
  HANDLE_FINICITY_POPUP_ERROR,
  ACTIONS,
  TOAST_MESSAGES,
} from "./constants"
import {
  cleanConnectLink,
  createFinicityConnectLinkApiState,
  getIncomeVerificationApiState,
  submitIncomeVerification,
  updateIncomeVerificationStatus,
  updateIncomeVerificationStatusApiState,
} from "./action"
import { financialVerificationApiInstance } from "../../../utils/api-interceptor"

export const createFinicityConnectLinkApi = ({ query }) =>
  financialVerificationApiInstance.post(
    buildPath(CREATE_FINICITY_CONNECT_LINK_API, {
      rentalSubmissionId: query.rentalSubmissionId,
    }),
  )

export const updateIncomeVerificationStatusApi = ({ query }) =>
  financialVerificationApiInstance.patch(
    buildPath(UPDATE_INCOME_VERIFICATION_STATUS_API, query),
  )

export function* createFinicityConnectLinkSaga({ payload }) {
  const { rentalSubmissionId } = payload
  yield put(createFinicityConnectLinkApiState.request())
  try {
    const response = yield call(createFinicityConnectLinkApi, {
      query: {
        rentalSubmissionId,
      },
    })
    yield put(createFinicityConnectLinkApiState.success(response))
  } catch (error) {
    console.error("createFinicityConnectLinkSaga error -> ", error)
    yield put(createFinicityConnectLinkApiState.failure(error))
  }
}

export const generateIncomeVerificationReportApi = ({ query }) =>
  financialVerificationApiInstance.post(
    buildPath(GENERATE_INCOME_VERIFICATION_REPORT_API, {
      rentalSubmissionId: query.rentalSubmissionId,
    }),
  )

export const getIncomeVerificationApi = ({ query }) =>
  financialVerificationApiInstance.get(
    buildPath(GET_INCOME_VERIFICATION_API, {
      rentalSubmissionId: query.rentalSubmissionId,
    }),
  )

// eslint-disable-next-line consistent-return
export function* getIncomeVerificationSaga({ payload }) {
  const { rentalSubmissionId } = payload
  yield put(getIncomeVerificationApiState.request())
  try {
    const response = yield call(getIncomeVerificationApi, {
      query: {
        rentalSubmissionId,
      },
    })
    yield put(getIncomeVerificationApiState.success(response))
    return response
  } catch (error) {
    yield put(getIncomeVerificationApiState.failure(error))
  }
}

export function* updateIncomeVerificationStatusSaga({ payload }) {
  const { rentalSubmissionId, action } = payload
  yield put(updateIncomeVerificationStatusApiState.request())
  try {
    const response = yield call(updateIncomeVerificationStatusApi, {
      query: {
        rentalSubmissionId,
        action,
      },
    })
    yield put(updateIncomeVerificationStatusApiState.success(response))
  } catch (error) {
    console.error("updateIncomeVerificationStatusSaga error -> ", error)
    yield put(updateIncomeVerificationStatusApiState.failure(error))
  }
}

export function* handleFinicityPopupDoneSaga({ payload }) {
  const { rentalSubmission } = payload
  yield all([
    put(cleanConnectLink()),
    put(submitIncomeVerification()),
    put(
      updateIncomeVerificationStatus({
        rentalSubmissionId: rentalSubmission.id,
        action: ACTIONS.SUBMIT,
      }),
    ),
  ])
  yield put(redirect(ROUTE.DOCUMENT_UPLOAD, true))
  yield put(
    addToast({
      titleMessage: TOAST_MESSAGES.SUCCESS_MESSAGE,
      status: TOAST_TYPE.SUCCESS,
    }),
  )
}

export function* handleFinicityPopupErrorSaga() {
  yield put(
    addToast({
      titleMessage: TOAST_MESSAGES.FAIL_MESSAGE,
      status: TOAST_TYPE.ERROR,
    }),
  )
}

export function* watchHandleFinicityPopupDone() {
  yield takeLeading(HANDLE_FINICITY_POPUP_DONE, handleFinicityPopupDoneSaga)
}

export function* watchHandleFinicityPopupError() {
  yield takeLeading(HANDLE_FINICITY_POPUP_ERROR, handleFinicityPopupErrorSaga)
}

export function* watchGetFinicityConnectLink() {
  yield takeEvery(
    CREATE_FINICITY_CONNECT_LINK_CALL,
    createFinicityConnectLinkSaga,
  )
}

export function* watchUpdateIncomeVerificationStatus() {
  yield takeEvery(
    UPDATE_INCOME_VERIFICATION_STATUS_CALL,
    updateIncomeVerificationStatusSaga,
  )
}

export function* watchGetIncomeVerification() {
  yield takeEvery(GET_INCOME_VERIFICATION_CALL, getIncomeVerificationSaga)
}

export function* rootSaga() {
  yield all([
    watchGetIncomeVerification(),
    watchGetFinicityConnectLink(),
    watchUpdateIncomeVerificationStatus(),
    watchHandleFinicityPopupDone(),
    watchHandleFinicityPopupError(),
  ])
}

export default rootSaga
