import {
  takeLatest,
  put,
  call,
  all,
  fork,
  select,
  delay,
  spawn,
} from "redux-saga/effects"
import { buildPath } from "@rentspree/path"
import * as Sentry from "@sentry/browser"
import FileSaver from "file-saver"
import { push } from "connected-react-router"
import { UpdateEnvelopeSignError } from "utils/errors"

import {
  createPublicContact,
  postSubmitRental,
} from "containers/rental-submission/rental-guide/saga"
import { getPayment } from "containers/rental-submission/rental-payment/actions"

import { isIOS } from "components/helpers/device-detection"
import errorMessage, {
  E_SIGN_ERROR_MESSAGE,
  GENERAL_ERROR,
} from "constants/error-messages"
// import { updateEnvelopeTrackerMapper } from "tracker/tracker-mapper"
// import tracker from "tracker"
import {
  findSignerByRecipient,
  findSignerByOwner,
  mapFilesForSign,
} from "helpers/signing"
import SweetAlert from "components/sweetalert"
import { ALERT_PRESET, API_ERRORS } from "components/sweetalert/constants"
import { E_SIGN_TERM, E_SIGN_VERSION } from "constants/terms"
import {
  selectRentalId,
  selectLraEnvelopeId,
  makeIsPayAndSubmit,
} from "containers/rental-submission/selectors"
import { apiInstance, userInstance } from "utils/api-interceptor"
import { locationReload } from "utils/call-window"
import { tracker } from "tracker"
import {
  LRA_REVIEW_AND_SIGN,
  SIGNATURE_TYPE_PROPERTIES,
  PAYMENT_EVENT,
  needPayment,
} from "tracker/tracker-const"

import {
  envelopeApi,
  updateEnvelopeSignApi,
  uploadSignatureApi,
  saveSignSignature,
  editSignSignature,
  savingFilesApi,
  openSweetAlertTryAgainError,
  updateEnvelopeConsentApi,
  updateConsentLeaseAgreementApi,
  generateEnvelopePDFApi,
  generatePdfFileURLApi,
} from "./actions"
import {
  GET_ENVELOPE,
  SHARE_ENVELOPE,
  UPDATE_ENVELOPE_SIGN,
  UPLOAD_SIGNATURE,
  UPDATE_ENVELOPE_CONSENT,
  UPDATE_TEMPLATE_ENVELOPE,
  USER_TERMS_API,
  GENERATE_ENVELOPE_PDF,
  GET_ENVELOPE_PDF,
} from "./api-constants"
import {
  ENVELOPE_CALL,
  UPDATE_ENVELOPE_SIGN_CALL,
  UPLOAD_SIGN_SIGNATURE,
  SAVE_FILES_ENVELOPE,
  UPDATE_ENVELOPE_CONSENT_CALL,
  UPDATE_LEASE_AGREEMENT_CONSENT_CALL,
  GENERATE_ENVELOPE_PDF_CALL,
  RECIPIENT_ROLES,
  GENERATE_PDF_FILE_URL,
} from "./constants"
import {
  makeSelectIsAcceptTermsLeaseAgreement,
  selectUserIntegration,
} from "./selectors"
import {
  makeSelectIsSelectMultiShare,
  makeSelectAllowMultiShareOffer,
} from "../rental-submission/rental-guide/selectors"
import { APPLICATION_TYPE } from "../rental-submission/constants"

export const callAPI = ({ envelopeId, token }) =>
  apiInstance.get(buildPath(GET_ENVELOPE, { envelopeId }, { token }))

export const callUpdateEnvelopeSign = ({ rentalId, files }) =>
  apiInstance.put(buildPath(UPDATE_ENVELOPE_SIGN, { rentalId }), {
    files,
  })

export const callUpdateConsent = () =>
  userInstance.post(USER_TERMS_API, {
    termName: E_SIGN_TERM,
    version: E_SIGN_VERSION, // format is YYYYMMDD
  })

export const callUpdateEnvelopeTemplate = ({ envelopeId, payload }) =>
  apiInstance.put(buildPath(UPDATE_TEMPLATE_ENVELOPE, { envelopeId }), payload)

export const callShareEnvelope = ({
  envelopeId,
  propertyId,
  leaseAgreementId,
}) =>
  apiInstance.post(buildPath(SHARE_ENVELOPE, { envelopeId }), {
    propertyId,
    leaseAgreementId,
  })

export const callUploadSignature = ({
  fullName,
  initials,
  envelopeId,
  userToken,
}) =>
  apiInstance.post(buildPath(UPLOAD_SIGNATURE, { envelopeId }), {
    signature: {
      initials,
      fullName,
    },
    userToken,
  })

export const callUpdateEnvelopeConsent = ({ envelopeId, payload, options }) => {
  const { token } = options
  return apiInstance.put(
    buildPath(UPDATE_ENVELOPE_CONSENT, { envelopeId }, { token }),
    {
      ...payload,
    },
  )
}

export const callGenerateEnvelopePDF = ({ envelopeId, token }) =>
  apiInstance.post(buildPath(GENERATE_ENVELOPE_PDF, { envelopeId }, { token }))

export function* envelopeSaga({ payload }) {
  yield put(envelopeApi.request())
  try {
    const response = yield call(callAPI, payload)
    const { recipients, files, owner } = response

    const foundSignerByRecipient = yield call(findSignerByRecipient, recipients)
    const foundSignerByOwner = yield call(findSignerByOwner, recipients, owner)
    const signer = foundSignerByRecipient || foundSignerByOwner
    const mappedFiles = yield call(mapFilesForSign, files, signer)

    yield put(envelopeApi.success({ ...response, files: mappedFiles }))
  } catch (err) {
    yield put(envelopeApi.failure())
    yield call(
      SweetAlert,
      ALERT_PRESET.ERROR,
      API_ERRORS[500].option,
      API_ERRORS[500].callback,
    )
  }
}

export function* updateEnvelopeSignSaga({ payload }) {
  const { files, isNextToDocumentStep } = payload || {}
  yield put(updateEnvelopeSignApi.request())
  try {
    const rentalId = yield select(selectRentalId)
    const response = yield call(callUpdateEnvelopeSign, {
      rentalId,
      files,
    })
    yield put(updateEnvelopeSignApi.success(response))
    yield call(
      [tracker, "trackEvent"],
      LRA_REVIEW_AND_SIGN.SUCCESS_APPLICATION_SIGN,
      {
        recipient_role: RECIPIENT_ROLES.APPLICANT,
        template: APPLICATION_TYPE.CAR_LRA,
      },
    )
    const isPayment = yield select(makeIsPayAndSubmit())
    let clickSubmitEventProp = {}
    const isSelectedMultiShareOffer = yield select(
      makeSelectIsSelectMultiShare(),
    )
    const isAllowMultiShareOffer = yield select(
      makeSelectAllowMultiShareOffer(),
    )
    const isEligibleAndSelectMultiShareOffer =
      isAllowMultiShareOffer && isSelectedMultiShareOffer
    if (isPayment) {
      yield put(
        getPayment({
          provider: "stripe",
          multishare: isEligibleAndSelectMultiShareOffer,
        }),
      )
      clickSubmitEventProp = {
        ...needPayment("onPayAndSubmit"),
        select_multi_share: isEligibleAndSelectMultiShareOffer,
      }
    } else {
      clickSubmitEventProp = {
        ...needPayment("onSubmit"),
        select_multi_share: "none",
      }
      yield call(postSubmitRental, {
        payload: {
          isNextToDocumentStep,
        },
      })
      yield spawn(createPublicContact)
    }
    yield call(
      [tracker, "trackEvent"],
      PAYMENT_EVENT.clickSubmit,
      clickSubmitEventProp,
    )
  } catch (err) {
    yield call(
      Sentry.captureException,
      new UpdateEnvelopeSignError(JSON.stringify(payload), JSON.stringify(err)),
    )
    err.message = errorMessage.generalSaveError
    yield put(
      openSweetAlertTryAgainError(
        {
          title: E_SIGN_ERROR_MESSAGE.SIGN_ERROR.TITLE,
          text: E_SIGN_ERROR_MESSAGE.SIGN_ERROR.MESSAGE,
        },
        locationReload,
      ),
    )
    yield put(updateEnvelopeSignApi.failure(err))
  }
}

export function* uploadSignatureSaga({
  payload: { type, fullName, initials, envelopeId, userToken },
  mode,
}) {
  yield put(uploadSignatureApi.request())
  try {
    const response = yield call(callUploadSignature, {
      fullName,
      initials,
      envelopeId,
      userToken,
    })
    yield put(uploadSignatureApi.success())
    if (mode === "save") {
      yield put(
        saveSignSignature({
          signatureData: {
            fullName: response.fullName,
            initials: response.initials,
            type,
          },
        }),
      )
      yield call(
        [tracker, "trackEvent"],
        LRA_REVIEW_AND_SIGN.ADOPT_SIGNATURE,
        SIGNATURE_TYPE_PROPERTIES.DRAW,
      )
    } else if (mode === "edit") {
      yield put(
        editSignSignature({
          signatureData: {
            fullName: response.fullName,
            initials: response.initials,
            type,
          },
        }),
      )
      yield call(
        [tracker, "trackEvent"],
        LRA_REVIEW_AND_SIGN.REPLACE_SIGNATURE,
        SIGNATURE_TYPE_PROPERTIES.DRAW,
      )
    }
  } catch (err) {
    err.message = errorMessage.generalSaveError
    yield put(uploadSignatureApi.failure(err))
  }
}

export function* generateEnvelopePDFSaga({ token, callback }) {
  const envelopeId = yield select(selectLraEnvelopeId)
  yield put(generateEnvelopePDFApi.request())
  try {
    const response = yield call(callGenerateEnvelopePDF, {
      envelopeId,
      token,
    })
    yield put(generateEnvelopePDFApi.success(response))
    yield spawn(callback)
  } catch (err) {
    yield put(generateEnvelopePDFApi.failure(err))
  }
}

export const getFiles = async url => {
  const { envelopePdf, fileName } = await apiInstance.get(url)
  const uint8Buffer = new Uint8Array(envelopePdf.data)
  const fileData = new Blob([uint8Buffer], {
    type: "application/pdf",
  })
  const fileURL = URL.createObjectURL(fileData)
  if (isIOS()) {
    window.open(fileURL)
    return Promise.resolve({ fileData, isIOS: true })
  }

  return {
    fileData,
    fileName,
    fileURL,
  }
}

export function* saveFiles({ payload: { pdfPath, _id: envelopeId } }) {
  yield put(savingFilesApi.request(envelopeId))

  try {
    const rentalId = yield select(selectRentalId)
    const URL = buildPath(GET_ENVELOPE_PDF, { rentalId, envelopeId })
    const { fileData, fileName: fileNameFromResponse } = yield call(
      getFiles,
      URL,
    )
    const fileName = pdfPath.split("/")[2] || fileNameFromResponse
    if (!isIOS()) {
      yield fork([FileSaver, "saveAs"], fileData, fileName)
    }
    yield put(savingFilesApi.success({ envelopeId }))
  } catch (err) {
    yield put(
      openSweetAlertTryAgainError(
        {
          title: GENERAL_ERROR.UNKNOWN_ERROR.TITLE,
          text: GENERAL_ERROR.UNKNOWN_ERROR.MESSAGE,
        },
        locationReload,
      ),
    )
    yield put(savingFilesApi.failure({ err, envelopeId }))
  }
}

export function* generatePdfFileURL({ payload: { envelopeId, callback } }) {
  yield put(generatePdfFileURLApi.request(envelopeId))
  try {
    const rentalId = yield select(selectRentalId)
    const URL = buildPath(GET_ENVELOPE_PDF, { rentalId, envelopeId })
    const { fileURL } = yield call(getFiles, URL)
    yield put(generatePdfFileURLApi.success())
    yield call(callback, fileURL)
  } catch (error) {
    yield put(generatePdfFileURLApi.failure({ error }))
  }
}

export function* updateEnvelopeConsent({ payload, envelopeId, options }) {
  yield put(updateEnvelopeConsentApi.request())
  try {
    const response = yield call(callUpdateEnvelopeConsent, {
      envelopeId,
      payload,
      options,
    })
    yield put(updateEnvelopeConsentApi.success(response))
  } catch (err) {
    yield put(updateEnvelopeConsentApi.failure(err))
  }
}

export function* checkEligibleToSelectTemplate(digitalDocTemplate) {
  try {
    const userIntegration = yield select(selectUserIntegration)
    const linked = userIntegration.includes(digitalDocTemplate.provider)
    if (linked) {
      return
    }
    const error = new Error()
    error.status = 403
    throw error
  } catch (error) {
    throw error
  }
}

export const selectIsAcceptTermsLeaseAgreement = makeSelectIsAcceptTermsLeaseAgreement()

export function* updateConsentSelectOption({ nextPath }) {
  yield put(updateConsentLeaseAgreementApi.request())
  try {
    yield call(callUpdateConsent)
    yield put(
      updateConsentLeaseAgreementApi.success({
        isConsentSuccess: true,
        isShowConsentModal: true,
      }),
    )
    yield delay(1000)
    yield put(
      updateConsentLeaseAgreementApi.success({
        isShowConsentModal: false,
        isConsentSuccess: false,
      }),
    )
    yield put(push({ pathname: nextPath }))
  } catch (err) {
    yield put(updateConsentLeaseAgreementApi.failure(err))
  }
}

export function* watchApiCall() {
  yield takeLatest(ENVELOPE_CALL, envelopeSaga)
}

export function* watchGenerateEnvelopePDFCall() {
  yield takeLatest(GENERATE_ENVELOPE_PDF_CALL, generateEnvelopePDFSaga)
}
export function* watchUpdateEnvelopeSign() {
  yield takeLatest(UPDATE_ENVELOPE_SIGN_CALL, updateEnvelopeSignSaga)
}
export function* watchUploadSign() {
  yield takeLatest(UPLOAD_SIGN_SIGNATURE, uploadSignatureSaga)
}

export function* watchSaveFiles() {
  yield takeLatest(SAVE_FILES_ENVELOPE, saveFiles)
}
export function* watchGeneratePdfFileURL() {
  yield takeLatest(GENERATE_PDF_FILE_URL, generatePdfFileURL)
}
export function* watchUpdateEnvelopeConsent() {
  yield takeLatest(UPDATE_ENVELOPE_CONSENT_CALL, updateEnvelopeConsent)
}

export function* watchUpdateConsentSelectOption() {
  yield takeLatest(
    UPDATE_LEASE_AGREEMENT_CONSENT_CALL,
    updateConsentSelectOption,
  )
}

export const watchers = [
  watchApiCall(),
  watchUpdateEnvelopeSign(),
  watchUploadSign(),
  watchSaveFiles(),
  watchUpdateEnvelopeConsent(),
  watchUpdateConsentSelectOption(),
  watchGenerateEnvelopePDFCall(),
  watchGeneratePdfFileURL(),
]

export function* rootSaga() {
  yield all(watchers)
}

export default rootSaga
