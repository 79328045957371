import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"
import makeSelectLoading from "containers/loading/selectors"

import {
  setActive,
  resetActive,
  setChildrenBox,
} from "containers/drag-n-drop/actions"
import { redirect } from "containers/wrapper/actions"
import { makeSelectDocuments } from "containers/rental-submission/rental-guide/selectors"

import {
  makeSelectActiveId,
  makeSelectRecipientList,
  selectLoadingUpdateEnvelope,
  selectFieldValidationResult,
  selectSignatureData,
  selectIsOwner,
  makeSelectShowLeavePopup,
  selectIsUploadingSignature,
  selectLoadingSavingFile,
  selectLoadingGenerateEnvelopePDF,
  selectIsSigned,
  selectIsAcceptedConsent,
  selectLoadingUpdateConsent,
  selectUpdateConsentSuccess,
  selectEnvelopeError,
  makeSelectDoesEnvelopeExist,
  selectEnvelopeData,
  makeSelectUploadSignatureSuccess,
  makeSelectIsSignatureEdited,
  selectIsLoadingGeneratePdfFileURL,
} from "../selectors"
import {
  updateEnvelopeSign,
  dateSignedSave,
  updateEnvelopeConsent,
  editSignSignature,
  saveSignSignature,
  uploadSaveSignSignature,
  uploadEditSignSignature,
} from "../actions"
// import { clearError } from "../../errors/actions"
import { makeSelectRental } from "../../../containers/rental-submission/selectors"

export const makeMapStateToProps = () =>
  createStructuredSelector({
    envelope: selectEnvelopeData(),
    haveEnvelope: makeSelectDoesEnvelopeExist(),
    isLoadingSavingFile: selectLoadingSavingFile,
    recipientsList: makeSelectRecipientList(),
    fieldValidation: selectFieldValidationResult(),
    isSigned: selectIsSigned(),
    activeId: makeSelectActiveId(),
    isLoadingUpdateEnvelope: selectLoadingUpdateEnvelope,
    signatureData: selectSignatureData(),
    isOwner: selectIsOwner(),
    shouldHandleUnload: makeSelectShowLeavePopup(),
    isUploadingSignature: selectIsUploadingSignature,
    isAcceptedConsent: selectIsAcceptedConsent(),
    isLoadingUpdateConsent: selectLoadingUpdateConsent,
    updateConsentSuccess: selectUpdateConsentSuccess,
    error: selectEnvelopeError,
    isLoadingGenerateEnvelopePDF: selectLoadingGenerateEnvelopePDF,
    uploadSignatureSuccess: makeSelectUploadSignatureSuccess(),
    isSignatureEdited: makeSelectIsSignatureEdited(),
    isSubmittingRental: makeSelectLoading(),
    isLoadingGeneratePdfFileURL: selectIsLoadingGeneratePdfFileURL,
    documents: makeSelectDocuments(),
    rentalSubmission: makeSelectRental(),
  })

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        /* envelope actions */
        updateEnvelopeSign,
        dateSignedSave,
        updateEnvelopeConsent,
        editSignSignature,
        saveSignSignature,
        uploadSaveSignSignature,
        uploadEditSignSignature,
        // clearError,
        setActive,
        resetActive,
        setChildrenBox,
        redirect,
      },
      dispatch,
    ),
  }
}

export const withConnect = connect(
  makeMapStateToProps,
  mapDispatchToProps,
)
