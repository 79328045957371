import React from "react"
import styled from "styled-components"
import { BlueGradientButton } from "@rentspree/component-v2/dist/button/gradient"
import Button from "@rentspree/component-v2/dist/button"
import { Icon } from "@rentspree/component-v2/dist/icon"
import BackButton from "@rentspree/component-v2/dist/button/back"
import { COLOR } from "styles/settings"

const ButtonSetContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: Source Sans Pro, sans-serif;
  margin-top: 30px;
  margin-bottom: 40px;
  ${props => props.margin && `margin: ${props.margin}`};
  @media (max-width: 991px) {
    flex-direction: column;
  }
`

const PrevAndNextContainer = styled.div`
  display: flex;
  @media (max-width: 991px) {
    justify-content: ${props =>
      props.isShowPrev ? "space-between" : "flex-end"};
    border-bottom: 1px solid ${COLOR.borderGrey};
    padding-bottom: 20px;
  }
`

const BackButtonContainer = styled.div`
  @media (max-width: 991px) {
    order: 1;
    margin-top: 20px;
    #BackToDashBoardBtn {
      height: 22px;
    }
  }
`

const PrevButton = styled(Button)`
  background: ${COLOR.white};
  display: ${props => (props.isShowPrev ? "inherit" : "none")};
`

export const ButtonSet = ({
  hasIconNextBtn = true,
  onSaveAndNext,
  continueBtnType,
  isLoading,
  isSuccess,
  isShowPrev = true,
  continueText = "Continue",
  onClickPrevBtn,
  onClickBackToDashboardBtn,
  btnHeight,
  margin,
  hideBackButton,
  hidePrevButton,
  hideIconNextButton,
  text,
  mWidth,
  disabledNextButton,
  disabledBackButton,
  minWidth = "110",
}) => (
  <ButtonSetContainer margin={margin}>
    <BackButtonContainer>
      {!hideBackButton && (
        <BackButton
          text={text || "Back to dashboard"}
          height={btnHeight}
          grayDisabled={false}
          margin="0"
          padding="0"
          homeIcon
          semiBold
          size="16px"
          id="BackToDashBoardBtn"
          disabled={isLoading || disabledBackButton}
          onClick={onClickBackToDashboardBtn}
        />
      )}
    </BackButtonContainer>
    <PrevAndNextContainer isShowPrev={isShowPrev}>
      {!hidePrevButton && (
        <PrevButton
          id="goToPrevPageBtn"
          height={btnHeight}
          semiBold
          google
          small
          margin="0 15px 0 0"
          type="button"
          isShowPrev={isShowPrev}
          size="16px"
          minWidth={minWidth}
          disabled={isLoading}
          onClick={onClickPrevBtn}>
          <Icon name="chevron-left" size="sm" />
          Prev
        </PrevButton>
      )}
      <BlueGradientButton
        disabled={isLoading || disabledNextButton}
        hasIcon={!hideIconNextButton && hasIconNextBtn}
        small
        height={btnHeight}
        text={continueText}
        id="nextButton"
        semiBold
        mWidth={mWidth}
        onClick={onSaveAndNext}
        type={continueBtnType}
        loading={isLoading}
        loaded={isSuccess}
        size="16px"
        minWidth={minWidth}
        tWidth={hidePrevButton ? "100%" : ""}
      />
    </PrevAndNextContainer>
  </ButtonSetContainer>
)
