import isEmpty from "lodash/isEmpty"
import get from "lodash/get"
import isEqual from "lodash/isEqual"
// TODO: INIT-649 cleanup
import { Statsig } from "statsig-react"

import { makeSelectMultiShare } from "containers/wrapper/selectors"
import { makeSelectProperty } from "containers/property/selectors"
import { createSelector } from "reselect"
import { APPLICATION_TYPE } from "containers/rental-submission/constants"
import { EXPERIMENT_NAMES } from "utils/experiment/constants"

import { initialState } from "./reducer"

export const selectScreeningOption = state =>
  state.get("screeningOption", initialState)

export const makeSelectScreeningOption = () =>
  createSelector(selectScreeningOption, substate => substate.toJS())

export const makeSelectScreeningOptionCreatedAt = createSelector(
  selectScreeningOption,
  substate => substate.toJS().createdAt,
)

// TODO: INIT-649 - Cleanup
export const makeSelectPaymentUpfrontCriteria = () =>
  createSelector(
    makeSelectScreeningOption(),
    makeSelectProperty(),
    makeSelectMultiShare(),
    (screeningOption, property, { isMultiShareActive }) => {
      if (isEmpty(screeningOption) || isEmpty(property)) {
        return false
      }

      const isCarApp = isEqual(
        get(screeningOption, "applicationType"),
        APPLICATION_TYPE.CAR_LRA,
      )
      const isRenterPay = isEqual(get(screeningOption, "payerType"), "renter")
      const isPremium = isEqual(get(screeningOption, "premium"), true)

      const hasCredit = isEqual(get(screeningOption, "credit_report"), true)
      const hasIncome = isEqual(get(screeningOption, "income"), true)
      const hasProOption = !isEmpty(get(screeningOption, "proOption"))

      if (
        isCarApp ||
        isMultiShareActive ||
        !isRenterPay ||
        isPremium ||
        !hasCredit ||
        hasIncome ||
        hasProOption
      ) {
        return false
      }

      return true
    },
  )

// TODO: INIT-649 cleanup
export const makeSelectPaymentUpfrontExperiment = () =>
  createSelector(makeSelectPaymentUpfrontCriteria(), isMeetCriteria => {
    if (!isMeetCriteria) return undefined
    const exp = Statsig.getExperiment(
      EXPERIMENT_NAMES.PAYMENT_PLACEMENT_IN_APPLICATION_FLOW_EXPERIMENT,
    ).get("report_flow")
    return exp
  })

export const selectInstruction = state => state.get("instruction", initialState)

export const makeSelectExperiment = () =>
  createSelector(selectInstruction, substate => substate.toJS().experiment)
