import React from "react"
import { compose } from "redux"
import isEmpty from "lodash/isEmpty"
import get from "lodash/get"
import isEqual from "lodash/isEqual"
import { withRouter } from "react-router-dom"
import { generateAddress } from "@rentspree/helper"
import styled from "styled-components"
import { T36, Hr, B16, BlueGradientButton } from "@rentspree/component-v2"
import S20 from "@rentspree/component-v2/dist/typography/s20"
import Amplitude from "amplitude-js"
import TransUnionLogo from "images/icon/transunion-full-logo.svg"

import { InstructionWrapper } from "components/layout"
import {
  InstructionList,
  ApplicationFeeAndMessage,
} from "components/instruction"
import { RentalDetail } from "components/guide/rental-detail"
import { FlexContainer } from "components/guide/rental-step"
import { tracker, withTracker } from "tracker"
import {
  RENTAL_PAGE_NAME,
  RENTAL_INSTRUCTION_EVENT,
} from "tracker/tracker-const"

import { withConnect, withSaga, withReducer } from "./connect"

const RentalDetailTitle = styled(T36)`
  margin-top: "50px";
  margin-bottom: "30px";
`

export const OrderList = styled.ol`
  padding-left: 20px;
  margin-top: 16px;
  margin-bottom: 30px;
  li {
    margin-bottom: 2px;
  }
`

export const UnorderList = styled.ul`
  padding-left: 20px;
  margin-top: 16px;
  margin-bottom: 30px;
  li {
    margin-bottom: 2px;
  }
`

export class InstructionPage extends React.Component {
  // TODO: atlas will clean up statsig log
  componentDidUpdate(prevProps) {
    if (
      isEmpty(prevProps.screeningOption) &&
      !isEmpty(this.props.screeningOption)
    ) {
      tracker.trackEvent(RENTAL_INSTRUCTION_EVENT.ENTER_PAGE.EVENT_NAME, {
        [RENTAL_INSTRUCTION_EVENT.ENTER_PAGE.EVENT_PROPS
          .SCREENING_OPTION_PREMIUM]: this.props.screeningOption.premium,
      })
      // LNR-3649: temporary remove statsig event tracking due to quota limit
      // Statsig.logEvent(
      //   "apply_now::enter rental instruction",
      //   this.props.screeningRequestId,
      //   this.props.screeningOption,
      // )
    }
    // TODO: INIT-649 clean up
    if (
      this.props.paymentUpfrontCriteria &&
      !prevProps.paymentUpfrontCriteria
    ) {
      this.props.actions.evaluateExperiment()
    }
  }

  startNewRental = () => {
    Amplitude.getInstance().setUserProperties({
      partial_98: "experiment",
    })

    // TODO: INIT-649 clean up
    const { actions, screeningRequestId, rentalSubmission } = this.props
    const { isPrePaymentRequired = false } = rentalSubmission || {}
    if (isPrePaymentRequired) {
      actions.startPrePaymentRental(screeningRequestId)
    } else {
      actions.startNewRental(screeningRequestId)
    }
  }

  render() {
    const {
      screeningOption,
      property,
      loading,
      price,
      multiShare,
      rentalSubmission,
    } = this.props
    // TODO: INIT-649 cleanup
    const { isPrePaymentRequired = false } = rentalSubmission || {}
    const { isMultiShareActive } = multiShare
    const fee = price?.total
    const address = generateAddress(property)

    const isRenterPay = isEqual(get(screeningOption, "payerType"), "renter")
    const isIncludeCreditReport = get(screeningOption, "credit_report")
    const isRenterPayAndIncludeCreditReport =
      isRenterPay && isIncludeCreditReport

    const applicationFee = isMultiShareActive ? 0 : fee

    const isShowApplicationFee = isRenterPay || isMultiShareActive

    const shouldShowPaymentOption =
      isRenterPayAndIncludeCreditReport && !isMultiShareActive

    const renderMainApplyNowContent = () => (
      <InstructionWrapper>
        <RentalDetailTitle>Submit Your Application</RentalDetailTitle>
        <RentalDetail address={address} mMargin="10px 0 30px" />
        <Hr margin="10px 0 19px 0" />
        <InstructionList shouldShowPaymentOption={shouldShowPaymentOption} />
        <Hr margin="20px 0 20px 0" />
        <FlexContainer width="100%" alignItems="flex-start">
          <ApplicationFeeAndMessage
            fee={applicationFee}
            isShowApplicationFee={isShowApplicationFee}
            multiShare={multiShare}
            screeningOption={screeningOption}
          />
          <FlexContainer
            width="100%"
            alignItems="flex-start"
            margin="0 0 0 0"
            mMargin="0 0 20px 0">
            <BlueGradientButton
              small
              shadow
              hasIcon
              semiBold
              loading={loading.components}
              loaded={loading.success}
              text="Apply Now"
              onClick={this.startNewRental}
              minWidth={155}
              mWidth="100%"
              id="rentalApplyBtn"
            />
          </FlexContainer>
        </FlexContainer>
      </InstructionWrapper>
    )

    // TODO: INIT-649 - Cleanup
    const renderApplyNowContentForPaymentUpfront = () => (
      <InstructionWrapper>
        <RentalDetailTitle>
          It&#39;s easy to apply through RentSpree
        </RentalDetailTitle>
        <RentalDetail address={address} mMargin="10px 0 30px" />
        <S20>Apply in 3 easy steps</S20>
        <OrderList>
          <li>Pay the application fee</li>
          <li>Fill out your information</li>
          <li>Confirm details and submit</li>
        </OrderList>
        <S20>Included in your application package</S20>
        <UnorderList>
          {get(screeningOption, "application", false) && (
            <li>Rental application</li>
          )}
          {get(screeningOption, "credit_report", false) && (
            <li>Credit report</li>
          )}
          {get(screeningOption, "criminal", false) && <li>Background check</li>}
          {get(screeningOption, "eviction", false) && <li>Eviction history</li>}
        </UnorderList>
        <B16>
          RentSpree partners with{" "}
          <img
            style={{
              marginTop: "-13px",
              marginLeft: "5px",
              height: "22px",
            }}
            src={TransUnionLogo}
            alt="TransUnion Logo"
          />{" "}
          to process a soft inquiry so there is no impact to your credit score.
        </B16>
        <FlexContainer width="100%" alignItems="flex-start">
          <ApplicationFeeAndMessage
            fee={applicationFee}
            isShowApplicationFee={isShowApplicationFee}
            multiShare={multiShare}
            screeningOption={screeningOption}
            customLabel="Application fee"
          />
          <FlexContainer
            width="100%"
            alignItems="flex-start"
            margin="0 0 0 0"
            mMargin="0 0 20px 0">
            <BlueGradientButton
              small
              shadow
              hasIcon
              semiBold
              loading={loading.components}
              loaded={loading.success}
              text="Apply Now"
              onClick={this.startNewRental}
              minWidth={155}
              mWidth="100%"
              id="rentalApplyBtn"
            />
          </FlexContainer>
        </FlexContainer>
      </InstructionWrapper>
    )

    // TODO: INIT-649 - Cleanup
    const renderer = () =>
      isPrePaymentRequired
        ? renderApplyNowContentForPaymentUpfront()
        : renderMainApplyNowContent()

    return renderer()
  }
}

export default compose(
  withRouter,
  withConnect,
  withReducer,
  withSaga,
)(withTracker(InstructionPage, tracker, RENTAL_PAGE_NAME.INSTRUCTION))
