import { fromJS } from "immutable"
import { GET_SCREENING_REQUEST_SUCCESS } from "containers/wrapper/constants"
import { EVALUATE_EXPERIMENT_SUCCESS } from "./constants"

export const initialState = fromJS({
  experiment: undefined,
})

function screeningOptionReducer(state = initialState, action) {
  let nextState = state
  const { payload } = action
  switch (action.type) {
    case GET_SCREENING_REQUEST_SUCCESS:
      nextState = initialState.mergeDeep({
        ...payload.screeningOption,
        proOption: payload.proOption,
        createdAt: payload.created_at,
      })
      return nextState
    case EVALUATE_EXPERIMENT_SUCCESS:
      nextState = state.set("experiment", action.payload)
      return nextState
    default:
      return state
  }
}

export default screeningOptionReducer
