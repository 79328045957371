import React from "react"
import styled from "styled-components"
import {
  Button,
  BlueGradientButton,
  Hr,
  Icon,
  BackButton,
} from "@rentspree/component-v2"
import { breakpoints, COLOR } from "styles/settings"

const PlaceHolder = styled.div`
  ${breakpoints.mobileApplicationWithoutKeyboard(`
    height: 220px;
  `)};
`

const Container = styled.div`
  margin: 40px 0;

  ${breakpoints.mobileApplicationWithoutKeyboard(`
    z-index: 2;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 30px 20px 30px 20px;
    margin: 0;
    background-color: ${COLOR.white};
    border-top: 1px solid ${COLOR.lineGray};
    filter: drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.2));
  `)};
`

const RowButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ hasBackBtn }) =>
    !hasBackBtn &&
    breakpoints.mobileApplication(`
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: start;
  `)};
`

const SeparateLine = styled(Hr)`
  margin: 20px 0;
  width: 100%;
  display: ${({ hasBackBtn }) => (hasBackBtn ? "block" : "none")};

  ${breakpoints.mobileApplication(`
    display: block;
  `)};
`

const ContinueButton = styled(BlueGradientButton)`
  ${({ hasBackBtn }) =>
    !hasBackBtn &&
    breakpoints.mobileApplication(`
    width: 100%;
  `)};
`

const BackToGuideButton = styled(BackButton)`
  height: 22px;
`

export const ApplicationFormFooter = ({ continueBtn, backBtn, cancelBtn }) => {
  const hasBackBtn = !!backBtn
  return (
    <>
      <Container>
        {hasBackBtn ? (
          <>
            <RowButtonGroup hasBackBtn={hasBackBtn}>
              <Button
                semiBold
                google
                small
                margin="0 15px 0 0"
                type="button"
                minWidth={100}
                {...backBtn}>
                <Icon name="chevron-left" size="sm" />
                {backBtn?.text || "Prev"}
              </Button>
              <ContinueButton
                small
                hasIcon
                semiBold
                text="Save & Next"
                type="submit"
                minWidth={100}
                hasBackBtn={hasBackBtn}
                {...continueBtn}
              />
            </RowButtonGroup>
            <SeparateLine hasBackBtn={hasBackBtn} />
            <BackToGuideButton
              small
              padding="0px"
              homeIcon
              minWidth="150"
              justifyContent="flex-start"
              semiBold
              text="Back to guide"
              {...cancelBtn}
            />
          </>
        ) : (
          <RowButtonGroup hasBackBtn={hasBackBtn}>
            <BackToGuideButton
              small
              padding="0px"
              homeIcon
              minWidth="150"
              justifyContent="flex-start"
              semiBold
              text="Back to guide"
              {...cancelBtn}
            />
            <SeparateLine hasBackBtn={hasBackBtn} />
            <ContinueButton
              small
              hasIcon
              semiBold
              text="Save & Next"
              type="submit"
              minWidth={100}
              hasBackBtn={hasBackBtn}
              {...continueBtn}
            />
          </RowButtonGroup>
        )}
      </Container>
      <PlaceHolder />
    </>
  )
}
