import React from "react"
import isEmpty from "lodash/isEmpty"
import { Clearfix, Row, Col } from "react-bootstrap"
import { withFormik } from "formik"
import moment from "moment"
import styled from "styled-components"
import get from "lodash/get"
import { trackEvent } from "tracker"
import { LOG_EVENT, EVENT_RENTAL_APP_V3_PAGES } from "tracker/tracker-const"
import {
  RadioButton,
  TextInput,
  FormattedInput,
  S16,
  RadioGroupWithConditionalFields,
  Tooltip,
} from "@rentspree/component-v2"

import { COLOR } from "styles/settings"

import { ApplicationFormFooter } from "components/molecules/application-form-footer"
import ToolTipBody from "components/tooltip/tooltip-body-new"
import { Div } from "components/layout"
import ScrollToError from "components/scroll/scroll-to-error"
import Beforeunload from "components/alerts/beforeunload"
import DatePickerInput from "components/molecules/date-picker-input"
import { APPLICATION_TYPE } from "containers/rental-submission/constants"
import { stepApplicantInformationValidation } from "../validation-schema"
import {
  applyAsChoices,
  FooterNote,
  APPLICANT_PATH_FLOW,
  choices,
  APPLICANT_TYPE,
} from "../constants"
import { checkIsMonthlyRentRequired } from "../helpers"

const RedAsterisk = styled.span`
  color: #f15555;
  font-weight: normal;
`

export const InnerForm = props => {
  const {
    actions,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    dirty,
    loading,
    rental,
    setFieldValue,
  } = props

  const validateContinueButton = () => {
    if (values.applicantType === APPLICANT_TYPE.TENANT) {
      return (
        isEmpty(values.applicantType) ||
        isEmpty(values.moveInDate) ||
        isEmpty(values.haveRenterAgent) ||
        (isCARApplication && isEmpty(values.monthlyRent))
      )
    }
    return (
      isEmpty(values.applicantType) ||
      isEmpty(values.haveRenterAgent) ||
      (isCARApplication && isEmpty(values.moveInDate)) ||
      (isCARApplication && isEmpty(values.monthlyRent))
    )
  }

  const application = get(rental, "application", {})
  const isCARApplication = application.type === APPLICATION_TYPE.CAR_LRA
  // NOTE: respect to the validation schema logic that will required monthlyRent for rental that is created after we released CAR LRA
  // After Regroup Application (lnr-3458) rent price is removed for rentspree app but still need to be maintained for CAR app
  const isMonthlyRentRequired = checkIsMonthlyRentRequired(
    isCARApplication,
    rental,
  )

  return (
    <form onSubmit={handleSubmit}>
      <ScrollToError containerError {...props} />
      <Beforeunload isDirty={dirty} />
      <Row>
        <Clearfix>
          <Col sm={12}>
            <S16 margin="0 0 15px">
              How are you applying?<span>
                {" "}
                <RedAsterisk>*</RedAsterisk>
              </span>
            </S16>
          </Col>
        </Clearfix>
      </Row>
      <Row>
        <Clearfix>
          <Col sm={4}>
            <Div margin="0px 0px 10px">
              <RadioButton
                name="applicantType"
                choice={applyAsChoices.tenant}
                id="applyAsTenant"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.applicantType && errors.applicantType}
                values={values.applicantType}
                checked={applyAsChoices.tenant.value === values.applicantType}
              />
              <RadioButton
                name="applicantType"
                id="applyAsGuarantor"
                choice={applyAsChoices.guarantor}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.applicantType && errors.applicantType}
                values={values.applicantType}
                checked={
                  applyAsChoices.guarantor.value === values.applicantType
                }
              />
            </Div>
          </Col>
        </Clearfix>
      </Row>
      <Row>
        <Clearfix>
          <Col sm={12}>
            <Div margin="0px 0px 30px">
              <FooterNote page="info" />
            </Div>
          </Col>
        </Clearfix>
      </Row>
      {isCARApplication || values.applicantType === APPLICANT_TYPE.TENANT ? (
        <Row>
          <Clearfix>
            <Col md={4} sm={6}>
              <Div margin="0px 0px 30px">
                <DatePickerInput
                  id="applicantMoveInDateInput"
                  name="moveInDate"
                  value={values.moveInDate}
                  formatType="date"
                  label="Desired Move-in Date"
                  labelChildren={
                    <span
                      id="move-date-pro-tip"
                      role="presentation"
                      onMouseEnter={() =>
                        trackEvent({
                          ...LOG_EVENT.rental_v3_1.proTipMessage,
                          customProperties: {
                            pages: EVENT_RENTAL_APP_V3_PAGES.PRO_TIP.MOVE_DATE,
                          },
                        })
                      }
                      onClick={() =>
                        trackEvent({
                          ...LOG_EVENT.rental_v3_1.proTipMessageMobile,
                          customProperties: {
                            pages: EVENT_RENTAL_APP_V3_PAGES.PRO_TIP.MOVE_DATE,
                          },
                        })
                      }>
                      <Tooltip
                        title="Pro Tip"
                        titleSize="14px"
                        iconTitle="16px"
                        colorTitle={COLOR.brightBlue}
                        weightTitle="600">
                        <ToolTipBody
                          header="Pro Tip"
                          body="It's okay if you don't know the exact date. You can enter the earliest date you would like to move."
                        />
                      </Tooltip>
                    </span>
                  }
                  isRequired
                  onValueChange={vals => {
                    setFieldValue("moveInDate", vals)
                  }}
                  handleChange={handleChange}
                  minDate={moment()
                    .startOf("day")
                    .toDate()}
                  onBlur={handleBlur}
                  error={touched.moveInDate && errors.moveInDate}
                />
              </Div>
            </Col>
          </Clearfix>
        </Row>
      ) : (
        <></>
      )}
      <Row>
        {isCARApplication && (
          <Clearfix>
            <Col md={4} sm={6}>
              <Div margin="0px 0px 30px">
                <FormattedInput
                  id="monthlyRentInput"
                  formatType="currency"
                  name="monthlyRent"
                  label="Monthly Rent"
                  onValueChange={vals => {
                    setFieldValue("monthlyRent", vals.value)
                  }}
                  placeholder="$"
                  allowNegative={false}
                  onBlur={handleBlur}
                  error={touched.monthlyRent && errors.monthlyRent}
                  value={values.monthlyRent}
                  isRequired={isMonthlyRentRequired}
                />
              </Div>
            </Col>
          </Clearfix>
        )}
      </Row>
      <Row>
        <Clearfix>
          <Col sm={12}>
            <S16>
              Are you being represented by a renter’s agent?{" "}
              <RedAsterisk>*</RedAsterisk>
            </S16>
          </Col>
        </Clearfix>
      </Row>
      <Row>
        <Clearfix>
          <Col md={12}>
            <RadioGroupWithConditionalFields
              choices={choices}
              handleChange={handleChange}
              handleBlur={handleBlur}
              name="haveRenterAgent"
              id="renterAgent"
              hiddenFieldNames="renterAgent"
              setFieldValue={setFieldValue}
              values={values}
              error={touched.haveRenterAgent && errors.haveRenterAgent}>
              <Div margin="10px 0 20px">
                <Clearfix>
                  <Row>
                    <Col sm={12} md={4}>
                      <TextInput
                        id="renterAgentFullName"
                        type="text"
                        name="renterAgent.fullName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={get(values, `renterAgent.fullName`, "")}
                        label="Agent name"
                        error={
                          get(touched, "renterAgent.fullName") &&
                          get(errors, "renterAgent.fullName")
                        }
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      <FormattedInput
                        formatType="phone"
                        id="renterAgentPhoneNumber"
                        name="renterAgent.phoneNumber"
                        value={get(values, "renterAgent.phoneNumber")}
                        onValueChange={target => {
                          setFieldValue("renterAgent.phoneNumber", target.value)
                        }}
                        onBlur={handleBlur}
                        error={
                          touched.renterAgent?.phoneNumber &&
                          errors.renterAgent?.phoneNumber
                        }
                        label="Agent phone"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={8}>
                      <TextInput
                        id="renterAgentEmail"
                        type="text"
                        name="renterAgent.email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={get(values, "renterAgent.email", "")}
                        error={
                          touched.renterAgent?.email &&
                          errors.renterAgent?.email
                        }
                        label="Agent email"
                      />
                    </Col>
                  </Row>
                </Clearfix>
              </Div>
            </RadioGroupWithConditionalFields>
          </Col>
        </Clearfix>
      </Row>
      <ApplicationFormFooter
        cancelBtn={{
          id: "applicantInformationCancelBtn",
          onClick: () => actions.redirect(),
          disabled: loading.components,
        }}
        continueBtn={{
          id: "applicantInformationContinueBtn",
          disabled: validateContinueButton(),
        }}
      />
    </form>
  )
}

const Form = withFormik({
  mapPropsToValues: props => stepApplicantInformationValidation(props).mapper,
  validationSchema: props => stepApplicantInformationValidation(props).schema,
  handleSubmit: (values, { props }) => {
    const isCARApplication =
      get(props, "rental.application.type") === APPLICATION_TYPE.CAR_LRA
    const isTenant = get(values, "applicantType") === APPLICANT_TYPE.TENANT
    const moveInDate =
      (isCARApplication || isTenant) && values.moveInDate
        ? values.moveInDate
        : ""
    const monthlyRent =
      isCARApplication && values.monthlyRent ? values.monthlyRent : ""

    props.actions.saveApplicantInfo({
      payload: { ...values, moveInDate, monthlyRent },
      route: APPLICANT_PATH_FLOW.APPLICANT_INFO,
      parser: "stepApplicantInformation",
      draft: props.draft,
      pathname: props.location.pathname,
      isResumeAppEnabled: props.rental.isResumeAppEnabled,
      savedContinuePath: props.rental.continuePath,
    })
  },
  enableReinitialize: true,
  displayName: "StepApplicantInformation",
})(InnerForm)

export default Form
