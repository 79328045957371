import React from "react"
import styled from "styled-components"
import { T24 } from "@rentspree/component-v2"

export class Tooltip extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      displayTooltip: false,
    }
    this.hideTooltip = this.hideTooltip.bind(this)
    this.showTooltip = this.showTooltip.bind(this)
  }

  hideTooltip() {
    this.setState({ displayTooltip: false })
  }
  showTooltip() {
    this.setState({ displayTooltip: true })
  }

  render() {
    return (
      <TooltipContainer>
        {this.state.displayTooltip && (
          <TooltipOverlay
            displayTooltip={this.state.displayTooltip}
            onClick={this.hideTooltip}
          />
        )}
        {this.state.displayTooltip && (
          <TooltipBubble width={this.props.bubbleWidth}>
            <TooltipBubbleHeader
              text={this.props.mobileHeaderText}
              onClose={this.hideTooltip}
            />
            {this.props.children}
          </TooltipBubble>
        )}
        <TooltipIcon
          className="fa fa-question-circle"
          onMouseOver={this.showTooltip}
          onFocus={this.showTooltip}
          onMouseLeave={this.hideTooltip}
          onBlur={this.hideTooltip}
          color={this.props.color}
          iconMargin={this.props.iconMargin}
        />
        <TooltipIconMobile
          className="fa fa-question-circle"
          onClick={this.showTooltip}
          iconMargin={this.props.iconMargin}
        />
      </TooltipContainer>
    )
  }
}

export const TooltipOverlay = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: block;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 15;
  }
`

export const TooltipBubbleHeader = ({ text, onClose }) => (
  <TooltipBubbleHeaderContainer>
    <T24>{text}</T24>
    <CloseIcon className="fa fa-times" onClick={onClose} />
  </TooltipBubbleHeaderContainer>
)

const TooltipBubbleHeaderContainer = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
`
export const TooltipContainer = styled.span`
  position: relative;
`
export const TooltipBubble = styled.div`
  width: ${props => (props.width ? props.width : "350px")};
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 150%;
  padding: 9px 15px;
  transform: translateY(-50%);
  background-color: white;
  box-shadow: 0 10px 20px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  &::after {
    content: "";
    position: absolute;
    border-right: 7px solid white;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    top: 50%;
    left: -7px;
    transform: translateY(-50%);
  }
  @media (max-width: 767px) {
    position: fixed;
    width: 100%;
    height: 100%;
    margin-top: 50px;
    border-radius: 0;
    left: 0;
    padding: 20px 29px 20px 25px;
    z-index: 15;
  }
`
export const TooltipIcon = styled.i`
  margin-left: ${props => (props.iconMargin ? props.iconMargin : "10px")};
  color: ${props => (props.color ? props.color : "inherit")};
  @media (max-width: 767px) {
    display: none !important;
  }
`
export const TooltipIconMobile = styled.i`
  display: none !important;
  @media (max-width: 767px) {
    display: initial !important;
    margin-left: ${props => (props.iconMargin ? props.iconMargin : "10px")};
    color: inherit;
  }
`
export const CloseIcon = styled.i`
  font-size: 20px;
  color: #9b9b9b;
  :hover {
    color: #aaaaaa;
  }
`
