import React from "react"
import urlJoin from "url-join"
import styled from "styled-components"

import { ROUTE } from "containers/router/constants"
export const SAVE_APPLICANT_INFO_CALL = "SAVE_APPLICANT_INFO_CALL"
export const SAVE_APPLICANT_INFO_REQUEST = "SAVE_APPLICANT_INFO_REQUEST"
export const SAVE_APPLICANT_INFO_SUCCESS = "SAVE_APPLICANT_INFO_SUCCESS"
export const SAVE_APPLICANT_INFO_FAILED = "SAVE_APPLICANT_INFO_FAILED"
export const RENTAL_SPECIFICS_AUTOSAVE_FROM_CTA =
  "RENTAL_SPECIFICS_AUTOSAVE_FROM_CTA"
export const SAVE_APPLICANT_INFO_API_URL = "api/v2/rental-submissions/:rentalId"
export const saveApplicantInfoCall = ({
  payload,
  route,
  parser,
  isLastStep,
  draftAction,
  continuePath,
}) => ({
  type: SAVE_APPLICANT_INFO_CALL,
  payload,
  route,
  parser,
  isLastStep,
  draftAction,
  continuePath,
})

export const saveApplicantInfoRequested = payload => ({
  type: SAVE_APPLICANT_INFO_REQUEST,
  payload,
})

export const saveApplicantInfoSuccess = payload => ({
  type: SAVE_APPLICANT_INFO_SUCCESS,
  payload,
})

export const saveApplicantInfoFailed = payload => ({
  type: SAVE_APPLICANT_INFO_FAILED,
  payload,
})

export const choices = [
  { label: "Yes", id: "yes", value: "yes", showHidden: true },
  { label: "No", id: "no", value: "no" },
]

export const applyAsChoices = {
  tenant: { label: "Tenant", id: "tenant", value: "tenant" },
  guarantor: {
    label: (
      <span style={{ display: "-webkit-inline-box" }}>
        Co-signer or guarantor
      </span>
    ),
    id: "guarantor",
    value: "guarantor",
  },
}

export const APPLICANT_PATH_FLOW = {
  APPLICANT_INFO: {
    current: urlJoin(ROUTE.APPLICANT.BASE, ROUTE.APPLICANT.APPLICANT_INFO),
    next: urlJoin(ROUTE.APPLICANT.BASE, ROUTE.APPLICANT.ADDITIONAL_APPLICANT),
  },
  ADDITIONAL_APPLICANT: {
    current: urlJoin(
      ROUTE.APPLICANT.BASE,
      ROUTE.APPLICANT.ADDITIONAL_APPLICANT,
    ),
    previous: urlJoin(ROUTE.APPLICANT.BASE, ROUTE.APPLICANT.APPLICANT_INFO),
  },
}

export const CO_APPLICANT_TYPE = {
  OCCUPANT: "occupant",
  COSIGNER: "cosigner",
}

export const APPLICANT_TYPE = {
  TENANT: "tenant",
  GUARANTOR: "guarantor",
}

export const RENTAL_STATUS = {
  CREATED: "created",
  REQUESTED: "requested",
  STARTED: "started",
  READY: "ready",
  PROPERTY_REQUIRED: "property_required",
  SUBMITTED: "submitted",
  NOT_INTERESTED: "not_interested",
}
export const CHECKFORM_DESCRIPTION =
  "(ApplyLink™ will be sent to the email above when you submit reports.)"
export const GUARANTOR_FORM = {
  CHECK_BOX_LABEL: "Please send guarantor(s)/co-signer(s) ApplyLink™ to apply.",
  CHECK_BOX_DESC: CHECKFORM_DESCRIPTION,
}
export const CO_APPLICANT_CHECKBOX = {
  LABEL: "This person is over 18 years old",
  DESCRIPTION:
    "ApplyLink™ will be sent to the email you provided when you submit your application.",
}
export const OTHER_TENANTS_FORM = {
  CHECK_BOX_LABEL:
    "Please send co-applicant(s) ApplyLink™ so they can apply. (when over the age of 18)",
  CHECK_BOX_DESC: CHECKFORM_DESCRIPTION,
}

export const GUARANTOR_18_FOOTER =
  "Guarantors and co-signers must be over 18. Confirm with the agent or landlord if they’re allowed."
export const APPLICANT_18_FOOTER =
  "All applicants over the age of 18 must submit their own screening."

export const FooterNote = ({ prefix = "*", page }) => {
  const LabelFooterNote = styled.label`
    font-size: 14px;
    font-weight: normal;
  `
  let wording = ""
  switch (page) {
    case "info":
    case "guarantor":
      wording = GUARANTOR_18_FOOTER
      break
    case "tenant":
      wording = APPLICANT_18_FOOTER
      break
    default:
      break
  }
  return (
    <LabelFooterNote>
      {prefix} {wording}
    </LabelFooterNote>
  )
}

export const DRAFT_ACTION = {
  CREATE: "create",
  CLEAN: "clean",
}

export const RENTAL_SPECIFICS_STEP_LOOKUP_TABLE = {
  [`${ROUTE.APPLICANT.BASE}/`]: 0,
  [`${ROUTE.APPLICANT.BASE}${ROUTE.APPLICANT.APPLICANT_INFO}`]: 1,
  [`${ROUTE.APPLICANT.BASE}${ROUTE.APPLICANT.ADDITIONAL_APPLICANT}`]: 2,
}
